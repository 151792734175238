
.goods-info[data-v-e5c77ab4] {
    display: flex;
    margin: 10px 0px;
    height: 90px;
}
.goods-image[data-v-e5c77ab4]{
    width: 90px;
    height: 90px;
    flex-shrink: 0;
}
.goods-content[data-v-e5c77ab4] {
    flex-grow: 1;
    margin-left: 10px;
}
.goods-name[data-v-e5c77ab4] {
    font-size: 16px;
    font-weight: 400px;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}
.goods-price-number[data-v-e5c77ab4] {
    font-size: 14px;
    color: gray;
}
.goods-data-text[data-v-e5c77ab4] {
    font-size: 16px;
    word-spacing: 12px;
}
